import { getAssetScannerSubmitProjectPathByQrCodeId } from '@poly/utils';

export const routes = {
  login: '/login',
  onboarding: '/onboarding',

  createProject: getAssetScannerSubmitProjectPathByQrCodeId(':assetQrCodeId'),
  submitProject: '/submit-project/:assetQrCodeId/:projectDescription',
  projectCreated: '/project-created/:assetQrCodeId',
  existingProject: '/existing-project/:assetQrCodeId',

  help: '/help/:helpCode',
  helpLogin: '/help/login',
  helpNoAsset: '/help/no-asset',
  helpNotVerified: '/help/not-verified',
  helpDefault: '/help/default',
  home: '/',
  assetDetail: '/asset-detail/:assetId',
  propertyAssets: '/property-assets/:propertyId',
  notFoundProject: '/not-found-project',
  supplierCheckIn: '/supplier-check-in/:assetQrCodeId',
  supplierCheckInConfirm:
    '/supplier-check-in/confirm/:assetQrCodeId/:projectId',
  checkInSuccess: '/supplier-check-in/success',
  editAsset: '/edit-asset/:assetId',
  addAsset: '/add-asset',
  addAssetSuccess: '/add-asset/success',
  scanQrCode: '/scan-qr-code',
  scanQrCodeError: '/scan-qr-code/error',
  scanQrCodeInactive: '/scan-qr-code/inactive/:assetQrCodeId',
  projects: '/projects',
  projectDetail: '/project-detail/:projectId',
  projectFilter: '/project-filter',
  addProjectUpdate: '/add-update/:projectId',
  assetsQueue: '/assets-queue',
  editAssetsQueue: '/edit-assets-queue/:assetIdbKey',
  createWorkOrder: '/create-work-order/:assetQrCodeId',
  workOrderCreatedSuccess: '/work-order-success/:assetQrCodeId',
  forbiddenProjectCreation: '/forbidden-project-creation',
  assetProcedureSteps: '/maintenance-procedure-list',
  supplierCheckInQueue: '/check-in-queue',
};

export const PUBLIC_ROUTES = [
  routes.login,
  routes.help,
  routes.helpLogin,
  routes.helpNoAsset,
  routes.helpNotVerified,
  routes.helpDefault,
];
