import React, { useState } from 'react';
import {
  fetchUserToken,
  getLoginError,
  isErrorEqualTwoFAError,
} from '@poly/client-utils';

import { func, string, bool } from 'prop-types';
import LoginForm from './LoginForm.js';
import { TwoFAConfirmForm } from '../TwoFAConfirmForm.js';

export function LoginPageBase({
  onSubmitSuccess,
  forgotPasswordMessage,
  goToForgotPassword,
  title,
  hideLogo,
  className,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [credentials, setCredentials] = useState({});
  const [is2FAError, setIs2FAError] = useState(false);

  const handleSubmit = async (newCredentials) => {
    setLoading(true);
    setCredentials(newCredentials);
    try {
      await fetchUserToken(newCredentials);
      setLoading(false);
      await onSubmitSuccess(newCredentials);
    } catch (err) {
      setLoading(false);
      if (isErrorEqualTwoFAError(err)) {
        setIs2FAError(true);
      }
      const errorMsg = getLoginError(err);
      setError(errorMsg);
    }
  };

  const backToLogin = () => {
    setIs2FAError(false);
    setError(null);
    setCredentials({});
  };

  return is2FAError ? (
    <TwoFAConfirmForm
      className={className}
      credentials={credentials}
      handleSubmit={handleSubmit}
      errorMsg={error}
      setErrorMsg={setError}
      submitBtnText="Log In"
      loading={loading}
      goBack={backToLogin}
      title={title}
      hideLogo={hideLogo}
    />
  ) : (
    <LoginForm
      className={className}
      handleSubmit={handleSubmit}
      loading={loading}
      signInError={error}
      forgotPasswordMessage={forgotPasswordMessage}
      goToForgotPassword={goToForgotPassword}
      title={title}
      hideLogo={hideLogo}
    />
  );
}

LoginPageBase.propTypes = {
  title: string,
  onSubmitSuccess: func.isRequired,
  forgotPasswordMessage: string,
  goToForgotPassword: func,
  hideLogo: bool,
  className: string,
};
