import React from 'react';
import { Navigate, useLocation, useParams } from '@poly/client-routing';
import { node } from 'prop-types';
import { useIsTokenValid } from '@poly/client-utils';

import { routes } from '../routes.js';
import { useNetworkStatus } from '../providers/NetworkStatusProvider.js';

export function AuthRequired({ children }) {
  const { isOnline } = useNetworkStatus();
  const { isTokenValid } = useIsTokenValid(!isOnline);

  const location = useLocation();
  const params = useParams();

  if (isTokenValid || !isOnline) {
    return children;
  }

  return (
    <Navigate
      to={routes.login}
      replace
      state={{ from: { ...location, params } }}
    />
  );
}

AuthRequired.propTypes = {
  children: node.isRequired,
};
