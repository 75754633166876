import React from 'react';
import { useIsTokenValid } from '@poly/client-utils';
import { useNavigate, useParams, useRouterQuery } from '@poly/client-routing';
import styled from 'styled-components';

import { Text, SubTitleText } from '../components/Text.js';
import { Button } from '../components/Button.js';
import { routes } from '../routes.js';
import { CONTACT_MOBILE } from '../constants.js';
import {
  FlexColumnContainer,
  FlexContainer,
} from '../components/Containers.js';
import { PhoneLink } from '../components/PhoneLink.js';

const MessageText = styled(Text)`
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-bottom: 20px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexColumnContainer)`
  justify-content: space-between;
  height: 100%;
`;

const PhoneLinkContainer = styled(FlexContainer)`
  justify-content: center;
`;

const HelpCodes = {
  Login: 'login',
  NotVerified: 'not-verified',
  NoAsset: 'no-asset',
  Default: 'default',
};

const helpConfigs = {
  [HelpCodes.Login]: {
    message: 'Our support team would love to help assist you',
  },
  [HelpCodes.NotVerified]: {
    message: 'We could not verify your login',
  },
  [HelpCodes.NoAsset]: {
    message: "Sorry, we couldn't find the Asset you are looking for",
  },
  [HelpCodes.Default]: {
    message:
      'Hmmm... I am not sure how you got here, but we would love to help',
  },
};

const CenteredSubTitle = styled(SubTitleText)`
  text-align: center;
`;

export function HelpScreen() {
  const navigate = useNavigate();
  const { helpCode } = useParams();
  const { message: queryMessage } = useRouterQuery(['message']);
  const { isTokenValid } = useIsTokenValid();

  const { message: configMessage } =
    helpConfigs[helpCode] || helpConfigs[HelpCodes.Default];

  const message = queryMessage || configMessage;

  return (
    <Container>
      <MessageContainer>
        <MessageText>{message}</MessageText>
        <CenteredSubTitle>How to use this app?</CenteredSubTitle>
        <MessageText>
          {/* eslint-disable-next-line @cspell/spellchecker */}
          Please, scan asset&apos;s QR code and follow app&apos;s instructions.
          For additional assistance contact:
        </MessageText>
        <PhoneLinkContainer>
          <PhoneLink phone={CONTACT_MOBILE} />
        </PhoneLinkContainer>
      </MessageContainer>
      {!isTokenValid && (
        <Button
          caption="Back to login"
          iconName="backArrow"
          onClick={() => navigate(routes.login)}
        />
      )}
    </Container>
  );
}
