import * as R from 'ramda';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from '@poly/client-routing';
import { insertParamIntoURL, isNilOrEmpty } from '@poly/utils';
import { LoginPageBase } from '@poly/common-ui';
import {
  validateInternationalPhoneNumber,
  internationalPhoneUtils,
} from '@poly/client-utils';
import {
  SMS_CODE_REQUIRED_ERROR,
  USE_PASSWORD_LOGIN_ERROR,
} from '@poly/constants';
import { useApolloClient } from '@apollo/client';

import { routes } from '../../routes.js';
import { Link } from '../../components/Link.js';
import { Button } from '../../components/Button.js';
import { ErrorText } from '../../components/Text.js';
import { UserTypeComponent } from './UserTypeComponent.js';
import { InputSmsCode } from '../../components/InputSmsCode.js';
import { PhoneNumberInput } from '../../components/PhoneNumberInput/PhoneNumberInput.js';
import { useLoginRequest } from '../../hooks/useLoginRequest.js';
import { ResendCodeMessage } from '../../components/ResendCodeMessage.js';
import { GLOBAL_CLIENT_SELECTED_ID_KEY } from '../../components/GlobalClientSelect.js';

const US_COUNTRY_CODE = '+1';

export const ASSET_SCANNER_USER_TYPE_KEY = 'isSupplierUser';

// getPreviousLocationState :: Location -> Location
const getPreviousLocationState = R.pathOr({}, ['state', 'from']);

// getCurrentAssetQrCodeId :: Location -> String
const getCurrentAssetQrCodeId = R.compose(
  R.path(['params', 'assetQrCodeId']),
  getPreviousLocationState,
);

// getIsSupplierCheckIn :: Location -> Boolean
const getIsSupplierCheckIn = R.compose(
  R.path(['params', 'isSupplierCheckIn']),
  getPreviousLocationState,
);

// getPreviousUrl :: Location -> String
const getPreviousUrl = R.compose(R.prop('pathname'), getPreviousLocationState);

const LoginPasswordPage = styled(LoginPageBase)`
  background: none;
  height: 30vh;

  & > div {
    width: auto;
  }

  input[name='email'],
  input[name='code'] {
    width: 250px;
    height: 20px;
  }

  input[name='password'] {
    width: 205px;
    height: 20px;
  }
`;

export function LoginScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const { loading, request } = useLoginRequest();
  const [phone, setPhone] = useState(US_COUNTRY_CODE);
  const [isPasswordLogin, setIsPasswordLogin] = useState(false);
  const [isCodeRequested, setIsCodeRequested] = useState(false);
  const [codeExpiresAt, setCodeExpiresAt] = useState(null);
  const client = useApolloClient();

  const assetQrCodeId = getCurrentAssetQrCodeId(location);
  const isSupplierCheckInByParams = getIsSupplierCheckIn(location);

  const [isSupplierCheckIn, setIsSupplierCheckIn] = useState(
    isSupplierCheckInByParams,
  );

  const onChangeAuthCode = (e) => {
    const { value } = e.target;
    setCode(value);
    if (!isNilOrEmpty(value)) {
      setError('');
    }
  };

  const onLoginSuccess = async () => {
    localStorage.removeItem(GLOBAL_CLIENT_SELECTED_ID_KEY);
    await client.resetStore();
    await client.clearStore();

    localStorage.setItem(
      ASSET_SCANNER_USER_TYPE_KEY,
      isSupplierCheckIn || false,
    );

    const url = isSupplierCheckIn
      ? insertParamIntoURL(
          'assetQrCodeId',
          routes.supplierCheckIn,
          assetQrCodeId,
        )
      : getPreviousUrl(location);

    navigate(url || routes.home);
  };

  const onEnterAuthClick = async () => {
    if (isNilOrEmpty(code)) {
      setError('Code is required');
      return;
    }

    try {
      const supplierCheckInParams = isSupplierCheckIn
        ? {
            assetQrCodeId,
            supplierCheckIn: true,
          }
        : {};

      const { responseStatus, errorMessage } = await request({
        phone: internationalPhoneUtils.formattingInternationalPhone(phone),
        code,
        ...supplierCheckInParams,
      });

      if (responseStatus === 200) {
        await onLoginSuccess();
        return;
      }

      setError(errorMessage);
    } catch (err) {
      setError(err.message);
    }
  };

  const onEnterMobile = async () => {
    if (!validateInternationalPhoneNumber(phone)) {
      return setError('Invalid phone number');
    }

    setError('');

    try {
      const supplierCheckInParams = isSupplierCheckIn
        ? {
            assetQrCodeId,
            supplierCheckIn: true,
          }
        : {};

      const { responseStatus, errorMessage, extraErrorData } = await request({
        phone: internationalPhoneUtils.formattingInternationalPhone(phone),
        ...supplierCheckInParams,
      });

      if (
        responseStatus === SMS_CODE_REQUIRED_ERROR.code &&
        errorMessage === SMS_CODE_REQUIRED_ERROR.message
      ) {
        setCodeExpiresAt(extraErrorData?.expiresAt);
        return setIsCodeRequested(true);
      }

      if (
        responseStatus === USE_PASSWORD_LOGIN_ERROR.code &&
        errorMessage === USE_PASSWORD_LOGIN_ERROR.message
      ) {
        return setIsPasswordLogin(true);
      }

      if (responseStatus === 401) {
        return navigate(routes.helpNotVerified);
      }

      return setError(errorMessage);
    } catch (err) {
      return setError(err.message);
    }
  };

  if (R.isNil(isSupplierCheckIn) && assetQrCodeId) {
    return <UserTypeComponent handleSelect={setIsSupplierCheckIn} />;
  }

  if (isCodeRequested) {
    return (
      <>
        <InputSmsCode
          mask="_"
          autoFocus
          value={code}
          format="######"
          name="auth-code"
          onChange={onChangeAuthCode}
          autoComplete="one-time-code"
          label="Enter your 6 digit auth code"
        />
        {error && <ErrorText>{error}</ErrorText>}
        <ResendCodeMessage
          codeExpiresAt={codeExpiresAt}
          onEnterMobile={onEnterMobile}
        />
        <Link to={routes.helpLogin}>Need Help?</Link>
        <Button
          iconName="send"
          caption="Submit"
          disabled={!code}
          loading={loading}
          onClick={onEnterAuthClick}
        />
      </>
    );
  }

  if (isPasswordLogin) {
    return <LoginPasswordPage hideLogo onSubmitSuccess={onLoginSuccess} />;
  }

  return (
    <>
      <PhoneNumberInput
        value={phone}
        onChange={setPhone}
        name="mobile-number"
        label="Enter your mobile number"
      />
      {error && <ErrorText>{error}</ErrorText>}
      <Link to={routes.helpLogin}>Need Help?</Link>
      {isSupplierCheckInByParams ? (
        <Button
          inverted
          iconName="user"
          loading={loading}
          onClick={onEnterMobile}
          caption="Login as a Supplier"
        />
      ) : (
        <Button
          iconName="send"
          caption="Login"
          loading={loading}
          onClick={onEnterMobile}
        />
      )}
    </>
  );
}
